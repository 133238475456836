import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import bookImage from "./../Assets/book.jpg";
import "./Auth.css";
import * as mod from "./../../url";

const Login = () => {
  const [mobileOrEmail, setmobileOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const handleClick = () => setShow(!show);

  const submitHandler = async () => {
    setLoading(true);

    // Validate that both fields are filled
    if (!mobileOrEmail || !password) {
      toast({
        title: "Please fill all the blanks.",
        status: "warning",
        duration: 500,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const response = await axios.post(
        `${mod.api_url}/api/auth/user/login`,
        {
          mobileOrEmail,
          password,
        },
        config
      );
      localStorage.setItem("userInfo", JSON.stringify(response.data));
      sessionStorage.setItem("userInfo", JSON.stringify(response.data));

      // Check if the response indicates success
      if (response.status === 200) {
        toast({
          title: "Login Successful.",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/");
      } else {
        toast({
          title: "Enter Correct ID or Passwords.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error during login. Please check your credentials.";
      toast({
        title: "Error During Login.",
        description: errorMessage,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Flex
        height="100vh"
        alignItems="center"
        justifyContent="center"
        backgroundImage={`url(${bookImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
      >
        <Container width="600px" borderRadius="10px" border="2px solid black" padding="10px">
          <Container
          fontWeight="bold"
          backgroundColor="tomato"
          fontSize="2rem"
            border="2px solid black"
            padding="10px"
            borderRadius="30px"
            color="white"
          >
            GeniusGrid
          </Container>
          <Text fontSize="x-large" fontWeight="500">
            Login to your account
          </Text>
          <VStack spacing="5px" color="black">
            <FormControl id="email" isRequired>
              <FormLabel>Enter Email or Mobile</FormLabel>
              <Input
              border="2px solid black"
                placeholder="Enter Your email"
                onChange={(e) => setmobileOrEmail(e.target.value)}
                value={mobileOrEmail}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Enter Password</FormLabel>
              <InputGroup>
                <Input
                  border="2px solid black"
                  type={show ? "text" : "password"}
                  placeholder="Enter  Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <Button
             _hover={{ color: "black" , backgroundColor:"skyblue", transition:"all 0.3s ease-in-out"}}
              colorScheme="blue"
              width="100%"
              style={{ marginTop: 15 }}
              onClick={submitHandler}
              // isLoading={loading}
              isLoading={loading}
            >
              Login
            </Button>
          </VStack>
          <Box marginTop={2}>
            {" "}
            <Button
              _hover={{ color: "black" , backgroundColor:"aliceBlue", transition:"all 0.3s ease-in-out"}}
              colorScheme="green"
              width="48%"
              marginRight={1}
              as={Link}
              to="/register"
            >
              Don't have an account?
            </Button>
            <Button 
             _hover={{ color: "black" , backgroundColor:"aliceBlue", transition:"all 0.3s ease-in-out"}}
            colorScheme="red" width="48%" as={Link} to="/forget">
              Forgot your password?
            </Button>
          </Box>
        </Container>
      </Flex>
    </>
  );
};

export default Login;
