import React, { useEffect } from "react";
import aboutbanner from "./../Assets/about-us6.jpg";
import mission2 from "./../Assets/mission2.png";
import vision2 from "./../Assets/vision2.png";
import {
  Box,
  Flex,
  Image,
  Text,
  Heading,
  Center,
  Stack,
  Container,
} from "@chakra-ui/react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box position="sticky" top="0" zIndex="100">
        <Navbar />
      </Box>

      {/* Banner Section */}
      <Box>
        <Image src={aboutbanner} w="100%" objectFit="cover" alt="About Us" />
      </Box>

      {/* Mission Section */}
      <Container maxW="7xl" py={10}>
        <Center mb={10}>
          <Stack direction={{ base: "column", md: "row" }} align="center" spacing={8}>
            <Image src={mission2} boxSize="150px" alt="Mission" />
            <Box maxW="600px" textAlign={{ base: "center", md: "left" }}>
              <Heading fontSize="2xl" mb={4}>
                Our Mission
              </Heading>
              <Text fontSize="lg" color="gray.600">
                At GeniusGrid, our mission is to provide quality education
                accessible to all. We strive to create a platform where learners
                can find the resources and support they need to achieve their academic
                and professional goals.
              </Text>
            </Box>
          </Stack>
        </Center>

        {/* Vision Section */}
        <Center mb={8}>
          <Stack direction={{ base: "column", md: "row" }} align="center" spacing={4}>
            <Image src={vision2} boxSize="150px" alt="Vision" />
            <Box maxW="600px" textAlign={{ base: "center", md: "left" }}>
              <Heading fontSize="2xl" mb={4}>
                Our Vision
              </Heading>
              <Text fontSize="lg" color="gray.600">
                At GeniusGrid, our vision is to revolutionize education by
                providing accessible, high-quality learning experiences for
                students of all backgrounds. We aim to bridge educational gaps
                by offering a comprehensive, up-to-date curriculum that is
                thoughtfully curated to meet the unique needs of students.
              </Text>
            </Box>
          </Stack>
        </Center>

        {/* Offerings Section */}
        <Box py={8}>
          <Heading textAlign="center" fontSize="2xl" mb={8}>
            What We Offer
          </Heading>
          <Flex justify="center">
            <Box maxW="900px" textAlign="left" p={4} bg="gray.50" shadow="md" borderRadius="lg">
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Comprehensive Courses:
              </Text>
              <Text fontSize="md" mb={4}>
                We provide a wide range of courses covering essential subjects
                for students from classes 5 to 12. Our curriculum is designed to
                be both engaging and informative, ensuring that students grasp
                key concepts effectively.
              </Text>
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Live Classes and Webinars:
              </Text>
              <Text fontSize="md" mb={4}>
                Students can participate in live classes and webinars conducted
                by expert teachers, allowing them to learn in real-time and get
                their questions answered on the spot.
              </Text>
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Doubt Clearing Sessions:
              </Text>
              <Text fontSize="md" mb={4}>
                We offer regular doubt-clearing sessions where students can get
                personalized help on challenging topics, ensuring they never
                fall behind.
              </Text>
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Community and Support:
              </Text>
              <Text fontSize="md">
                Our platform fosters a strong sense of community among learners,
                encouraging collaboration and peer support. Students can join
                study groups, participate in discussions, and share resources.
              </Text>
            </Box>
          </Flex>
        </Box>
      </Container>

      <Footer />
    </>
  );
};

export default Aboutus;
