import React from 'react';
import { Box, Flex, Heading, Text, Link, Stack } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box as="footer" bg="gray.700" color="white" py="4">
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justify="space-evenly"
        width="full"
        mx="auto"
      >
        {/* Support Section */}
        <Box mb={2}>
          <Heading as="h3" fontSize="2xl" mb="2" color="blue.400" >
            Support
          </Heading>
          <Stack spacing={2}>
            <Link href="/about-us" fontSize="sm" _hover={{ textDecoration: 'underline' }}>
              About us
            </Link>
            <Link href="/contact-us" fontSize="sm" _hover={{ textDecoration: 'underline' }}>
              Help Center
            </Link>
            <Link href="/contact-us" fontSize="sm" _hover={{ textDecoration: 'underline' }}>
              Privacy Policy
            </Link>
            <Link href="/contact-us" fontSize="sm" _hover={{ textDecoration: 'underline' }}>
              Terms of Service
            </Link>
          </Stack>
        </Box>

        {/* Follow Us Section */}
        <Box mb={2}>
          <Heading as="h3" fontSize="2xl" mb={2} color="blue.400">
            Follow Us
          </Heading>
          <Stack spacing={2}>
            <Link href="https://facebook.com" isExternal fontSize="sm" _hover={{ textDecoration: 'underline' }}>
              Facebook
            </Link>
            <Link href="#" isExternal fontSize="sm" _hover={{ textDecoration: 'underline' }}>
              Twitter
            </Link>
            <Link href="https://instagram.com" isExternal fontSize="sm" _hover={{ textDecoration: 'underline' }}>
              Instagram
            </Link>
            <Link href="https://linkedin.com" isExternal fontSize="sm" _hover={{ textDecoration: 'underline' }}>
              LinkedIn
            </Link>
          </Stack>
        </Box>

        {/* Contact Section */}
        <Box mb={2}>
          <Heading as="h3" fontSize="2xl" color="blue.400">
            Contact
          </Heading>
          <Text fontSize="sm">
            Email: <Link href="mailto:info@geniusgrid.in" _hover={{ textDecoration: 'underline' }}>info@geniusgrid.in</Link>
          </Text>
          <Text fontSize="sm" mt={2}>
            Phone: <Link href="tel:+919430715717" _hover={{ textDecoration: 'underline' }}>+91 94307 15717</Link>
          </Text>
        </Box>
      </Flex>

      {/* Footer Bottom Section */}
      <Box textAlign="center" mt="2em">
        <Text color="blue.400" fontWeight="bold" fontSize="sm">&copy; 2024 GeniusGrid. All Rights Reserved.</Text>
      </Box>
    </Box>
  );
};

export default Footer;
