import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import axios from "axios";
import * as mod from "./../../url";
import courses5 from "./../Assets/coursesbanner.jpg";
import { useNavigate } from "react-router-dom";

const Courses = () => {
  const [Courses, setCourses] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const fetchCourses = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/course/get_All_course`
      );
      console.log("Fetched courses:", data.Courses); // Inspect the data
      setCourses(data?.Courses);
    } catch (error) {
      console.error("Error fetching courses:", error);
      toast({
        title: "Error fetching courses.",
        description: error.response?.data?.message || "Something went wrong!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const viewCourse = (_id) => {
    navigate(`/get_course/${_id}`);
  };

  const calculateDiscountedPrice = (price, offer) => {
    if (typeof price !== "number" || typeof offer !== "number") {
      console.error("Invalid price or offer value");
      return price;
    }
    return price - (price * offer) / 100;
  };

  const isOfferValid = (offerDate) => {
    if (!offerDate) return false;
    const today = new Date();
    const offerEndDate = new Date(offerDate);
    return today <= offerEndDate;
  };

  return (
    <>
      <Box position="sticky" top="0" zIndex="100">
        <Navbar />
      </Box>
         {/* Banner Section */}
         <Box>
        <Image src={courses5} w="100%" objectFit="cover" alt="courses-banner" />
      </Box>
      <Box >
        <Container maxW="container.xl" p={4}>
        <h1 style={{fontSize:"3rem", fontWeight:"bold", margin:"20px auto"}}>Our Courses</h1>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)", xl: "repeat(4, 1fr)" }}
            gap={6}
          >
            {Courses &&
              Courses.map((course, index) => (
                <GridItem
                  key={index}
                  border="2px solid black"
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <Image
                    src={course.image || "placeholder.jpg"}
                    alt={course.name}
                    width="100%"
                    height="30vh"
                    objectFit="contain"
                  />
                  <VStack p={2} align="center">
                    <Text fontSize="xl" fontWeight="bold">
                      {course.name.slice(0, 70)}
                    </Text>
                    <VStack align="center" display="inline">
                      <Text
                         bg="green.100"
                         padding="0.2em 1.3em"
                         borderRadius="3em"
                           fontSize="xl"
                           fontWeight="bold"
                           textAlign="center"
                           display="block"
                      >
                        Price:
                        <Text as="s" color="red" marginLeft="5px">
                          {" "}
                          {course.price} {course.currency}
                        </Text>
                        {isOfferValid(course.offerDate) && (
                          <p style={{ marginLeft: "10px", color: "green" }}>
                            {" "}
                            {calculateDiscountedPrice(
                              course.price,
                              course.offers
                            ).toFixed(2)}{" "}
                            {course.currency}
                          </p>
                        )}
                      </Text>
                    </VStack>
                    <Flex justifyContent="space-between">
                      <Button
                        colorScheme="blue"
                        width="100%"
                        onClick={() => {
                          if (userInfo) {
                            viewCourse(course.slug);
                          } else {
                            toast({
                              title: "Please log in to view this course.",
                              status: "error",
                              duration: 9000,
                              isClosable: true,
                            });
                            window.location.href = "/login";
                          }
                        }}
                      >
                        View Course
                      </Button>
                    </Flex>
                  </VStack>
                </GridItem>
              ))}
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Courses;
