import {
  Box,
  Button,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PiDotsThreeCircleLight } from "react-icons/pi";
import logo from "./../Assets/logo2.png";


const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("userInfo") !== null);
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("userInfo");
    setIsLoggedIn(false);
    navigate("/");
    window.location.reload();
  };

  const userdashbord = () => {
    navigate("/user-dashboard");
  };

  const loginhandle = () => {
    navigate("/login");
  };

  const registerhandle = () => {
    navigate("/register");
  };

  return (
    <nav style={{ background: "whitesmoke" }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="full"
      >
        { isMobile ?( 
<Box display="flex" width="50%">
  <Link to="/">
    <img src={logo} alt="Logo" style={{ width: "80px"}} />
  </Link>
  </Box>):
  (
    <Box display="flex" width="50%">
    <Link to="/">
      <img src={logo} alt="Logo" style={{ width: "80px" , marginLeft:"50px" }} />
    </Link>
    </Box>
  )
}
        {isMobile ? (
          <Box display="flex" width="full" justifyContent="space-between" alignItems="center">
            <Box>
            {isLoggedIn ? (
              <>
                <Button
                  marginRight={2}
                  colorScheme="blue"
                  onClick={logoutHandler}
                >
                  Logout
                </Button>
                <Button
                  marginRight={2}
                  colorScheme="green"
                  onClick={userdashbord}
                >
                  Dashboard
                </Button>
              </>
            ) : (
              <>
                <Button
                  marginRight={2}
                  colorScheme="blue"
                  onClick={loginhandle}
                >
                  Login
                </Button>
                <Button
                  marginRight={2}
                  colorScheme="blue"
                  onClick={registerhandle}
                >
                  Register
                </Button>
              
              </>
            )}
            </Box>
            <PiDotsThreeCircleLight
              onClick={onToggle}
              style={{
                height: "50px",
                width: "50px",
                color: "black",
                cursor: "pointer",
              }}
            />
          </Box>
        ) : (
          <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
            <Box display="flex" gap={6}>
              <Link to="/">
                <Button
                  color="black"
                  bg="white"
                  _hover={{ backgroundColor: "dodgerblue", color: "white" }}
                  border="2px solid black"
                  borderRadius="10px"
                   transition="all 0.3s ease-in-out"
                >
                  Home
                </Button>
              </Link>
              <Link to="/courses">
                <Button
                 color="black"
                 bg="white"
                 _hover={{ backgroundColor: "dodgerblue", color: "white" }}
                 border="2px solid black"
                 borderRadius="10px"
                  transition="all 0.3s ease-in-out"
                >
                   Courses
                </Button>
              </Link>
              <Link to="/about-us">
                <Button
                 color="black"
                 bg="white"
                 _hover={{ backgroundColor: "dodgerblue", color: "white" }}
                 border="2px solid black"
                 borderRadius="10px"
                  transition="all 0.3s ease-in-out"
                >
                 About
                </Button>
              </Link>
              <Link to="/contact-us">
                <Button
                color="black"
                bg="white"
                _hover={{ backgroundColor: "dodgerblue", color: "white" }}
                border="2px solid black"
                borderRadius="10px"
                 transition="all 0.3s ease-in-out"
                >
                  Contact
                </Button>
              </Link>
            </Box>
            <Box display="flex" marginRight="40px" alignItems="center" gap="10px">
              {isLoggedIn ? (
                <>
                  <Button
                    marginRight={2}
                    colorScheme="blue"
                    onClick={logoutHandler}
                    border="2px solid black"
                    borderRadius="25px"
                     transition="all 0.3s ease-in-out"
                  >
                    Logout
                  </Button>
                  <Button
                    marginRight={2}
                    colorScheme="green"
                    onClick={userdashbord}
                    border="2px solid black"
                    borderRadius="25px"
                     transition="all 0.3s ease-in-out"
                  >
                    {/* <MdOutlineDashboard /> */}
                    Dashboard
                  </Button>
                </>
              ) : (
                <>
                  <Button
                   p="15px"
                    onClick={loginhandle}
                    color="white"
                    bg="dodgerblue"
                    border="2px solid black"
                    borderRadius="15px"
                    _hover={{background:"black"}}
                     transition="all 0.3s ease-in-out"
                    
                  >
                    Login
                  </Button>
                  <Button
                    p="15px"
                    color="white"
                     bg="black"
                    onClick={registerhandle}
                    border="2px solid black"
                    borderRadius="15px"
                    transition="all 0.3s ease-in-out"
                    _hover={{background:"dodgerBlue"}}
                  >
                    Register
                  </Button>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
      {isOpen && (
        <Box
          display={{ base: "block", md: "none" }}
          position="absolute"
          top="60px"
          right="0"
          bg="white"
          width="100%"
          p={4}
          boxShadow="md"
        >
          <Link to="/">
            <Button marginBottom={2} width="100%" onClick={onClose}>
              Home
            </Button>
          </Link>
          <Link to="/courses">
            <Button marginBottom={2} width="100%" onClick={onClose}>
              Courses
            </Button>
          </Link>
         
          <Link to="/about-us">
            <Button marginBottom={2} width="100%" onClick={onClose}>
              About
            </Button>
          </Link>
          <Link to="/contact-us">
            <Button marginBottom={2} width="100%" onClick={onClose}>
              Contact
            </Button>
          </Link>
          {isLoggedIn ? (
            <>
              <Button
                marginBottom={2}
                width="100%"
                colorScheme="blue"
                onClick={logoutHandler}
              >
                Logout
              </Button>
              <Button
                marginBottom={2}
                width="100%"
                colorScheme="green"
                onClick={userdashbord}
              >
                Dashboard
              </Button>
            </>
          ) : (
            <>
              <Button
               marginBottom={2}
                width="100%"
                colorScheme="blue"
                onClick={loginhandle}
              >
                Login
              </Button>
              <Button
               marginBottom={2}
                width="100%"
                colorScheme="blue"
                onClick={registerhandle}
              >
                Register
              </Button>
            </>
          )}
        </Box>
      )}
    </nav>
  );
};

export default Navbar;
