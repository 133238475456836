import "./App.css";
import {
  Box,
  Image,
  useBreakpointValue
} from "@chakra-ui/react";
import React,{
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link
} from "react-router-dom";
import Login from "./Components/Auth/Login";
import Register from "./Components/Auth/Register";
import Forget from "./Components/Auth/Forget";
import whatsappicon from "./Components/Assets/whatsapp.png";
import Homepage from "./Components/Layout/Homepage";
import Courses from "./Components/Layout/Courses";
import Testseries from "./Components/Layout/Testseries";
import Aboutus from "./Components/Pages/Aboutus";
import Contact from "./Components/Pages/Contact";
import Home from "./Components/Dashboard/Home";
import CoursesDEtails from "./Components/Layout/CoursesDEtails";
import Checkout from "./Components/Layout/Checkout";
import LearnCourse from "./Components/Dashboard/LearnCourse";
// import PopupForm from "./Components/Layout/PopupForm";

function App() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo?.token;
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <div className="App">
  
      <Router>
        {
 isMobile ?(
  <Box className="whatsappbox" position="fixed" bottom="0" left="0" zIndex="10">
  <Link to="https://wa.me/9430715717" target="_blank">
  <Image className="whatsappicon" src={whatsappicon} height="6vh"/>
  </Link>
</Box>
 ):(  <Box className="whatsappbox" position="fixed" top="50vh" right="0" zIndex="10">
  <Link to="https://wa.me/9430715717" target="_blank">
  <Image className="whatsappicon" src={whatsappicon} height="9vh"/>
  </Link>
</Box>
)
        }
        <Routes>
          {/* {userInfo ? (
            <> */}
          <Route path="/user-dashboard" element={<Home />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/get_course/:slug" element={<CoursesDEtails />} />
          <Route path="/learn_course/:slug" element={<LearnCourse />} />
          <Route path="/checkout/:_id" element={<Checkout />} />
          <Route path="/test-seris" element={<Testseries />} />
          <Route path="/about-us" element={<Aboutus />} />
          {/* <Route path="/tets" element={<PopupForm />} /> */}
          <Route path="/contact-us" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" />} />
          {/* </>
          ) : (
            <> */}
          <Route path="/" element={<Homepage />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/get_course/:slug" element={<CoursesDEtails />} />
          <Route path="/checkout/:_id" element={<Checkout />} />
          <Route path="/test-seris" element={<Testseries />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="*" element={<Navigate to="/" />} />
          {/* </>
          )} */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
