import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  ModalFooter,
  Text,
  useDisclosure,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Select,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import * as mod from "./../../url";
import bookImage from "./../Assets/book.jpg";
import "./Auth.css";

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    // Check if all fields are filled
    if (
      firstName &&
      lastName &&
      email &&
      mobile &&
      password &&
      state &&
      city &&
      pincode
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [
    firstName,
    lastName,
    email,
    mobile,
    password,
    state,
    city,
    pincode,
  ]);

  const handleClick = () => setShow(!show);

  const generateOTP = async () => {
    onOpen();
    if (!email || !mobile) {
      toast({
        title: "Error",
        description: "Email and Mobile are required.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const { data } = await axios.post(
        `${mod.api_url}/api/auth/user/generate-otp`,
        { mobile, email, mode: "new user" }
      );
      toast({
        title: "OTP Sent",
        description: "Check your email or mobile for the OTP.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: error.response?.data?.message || "Error generating OTP",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const submitHandler = async () => {
    onClose();
    try {
      const { data } = await axios.post(
        `${mod.api_url}/api/auth/user/register`,
        {
          firstName,
          lastName,
          email,
          mobile,
          password,
          state,
          city,
          pincode,
          otp,
        }
      );
      toast({
        title: "Registration Successful",
        description: "You have successfully registered. Please log in.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Registration failed",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Flex
        height="100vh"
        alignItems="center"
        justifyContent="center"
        backgroundImage={`url(${bookImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
      >
        <Container width="400px" marginBottom="20vh" borderRadius="10px" border="2px solid black" padding="10px">
          <Container
            border="2px solid black"
            padding="10px"
            borderRadius="30px"
            fontWeight="800"
            fontSize="x-large"
            backgroundColor="tomato"
            color="white"
          >
            GeniusGrid
          </Container>
          <Text fontSize="x-large" fontWeight="500">
            Create new account
          </Text>
          <VStack spacing="5px" fontWeight="70" color="black">
            <Flex>
              <FormControl id="first-name" isRequired mr="4">
                <FormLabel>First Name</FormLabel>
                <Input
                  border="2px solid black"
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
              </FormControl>
              <FormControl id="last-name" isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                border="2px solid black"
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
              </FormControl>
            </Flex>

            <Flex width="100%">
              <FormControl id="State" isRequired >
                <FormLabel>State</FormLabel>
                <Input
                border="2px solid black"
                  placeholder="State"
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                />
              </FormControl>
            </Flex>
            <Flex>
              <FormControl id="city" isRequired mr="4">
                <FormLabel>City</FormLabel>
                <Input
                border="2px solid black"
                  placeholder="City"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
              </FormControl>
              <FormControl id="Pincode" isRequired>
                <FormLabel>Pincode</FormLabel>
                <Input
                border="2px solid black"
                  placeholder="Pincode"
                  onChange={(e) => setPincode(e.target.value)}
                  value={pincode}
                />
              </FormControl>
            </Flex>

            <Flex>
              <FormControl id="mobile" isRequired mr="4">
                <FormLabel>Mobile</FormLabel>
                <Input
                border="2px solid black"
                  placeholder="Mobile"
                  onChange={(e) => setMobile(e.target.value)}
                  value={mobile}
                />
              </FormControl>
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                border="2px solid black"
                  placeholder="Enter Your email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </FormControl>
            </Flex>
            <Flex>
              <FormControl id="password" isRequired mr="4">
                <FormLabel>Set Your Password</FormLabel>
                <InputGroup>
                  <Input
                  border="2px solid black"
                    type={show ? "text" : "password"}
                    placeholder="set a strong password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Box marginTop="2rem">
                <Button
                  colorScheme="blue"
                  width="100%"
                  onClick={generateOTP}
                  isDisabled={!isFormValid}
                >
                  Get OTP
                </Button>
              </Box>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader textAlign="center">Verify with OTP</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl id="otp" isRequired>
                    <FormLabel>Enter OTP</FormLabel>
                    <Input
                      placeholder="Enter Your OTP"
                      onChange={(e) => setOtp(e.target.value)}
                      value={otp}
                      width="100%"
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="red"
                    width="50%"
                    mr={3}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="blue"
                    width="100%"
                    onClick={submitHandler}
                  >
                    Register
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </VStack>
          <Box width="100%">
            <Button
            hover="black"
              colorScheme="green"
              width="46%"
              _hover={{ color: "black" , backgroundColor:"aliceBlue", transition:"all 0.3s ease-in-out"}}
              style={{ marginTop: 15 }}
              marginRight={2}
              as={Link}
              to="/login"
            >
             Already have Account?
            </Button>
          </Box>
        </Container>
      </Flex>
    </>
  );
};

export default Register;
