import React, { useEffect } from "react";
import "./Testseries.css";
import { Box, Button, Text } from "@chakra-ui/react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import testbanner from "./../Assets/test-series.jpg";
import tsaptitude from "./../Assets/tsaptitude.jpg";
import tsssc from "./../Assets/tsssc.jpg";
import tsrailway from "./../Assets/railwaycourse.jpg";
import tsk12 from "./../Assets/tsk12.jpg";
import tscuet from "./../Assets/tscuet.jpg";
import tsbanking from "./../Assets/tsbanking.jpg";
import tslanguagetest from "./../Assets/tslanguagetest.jpg";

const Testseries = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     <Box position="sticky" top="0" zIndex="100">
        <Navbar />
      </Box>
      {" "}
      <Box marginTop="80px">
        <div className="test-banner">
          <img src={testbanner} />
        </div>
        <Text fontSize="xx-large" fontWeight="600" fontFamily="poppins">
          Top Test Series
        </Text>
        <section className="test-series-container">
          <div className="test-grid">
            <div className="card">
              <div className="test-box">
                <img src={tsaptitude} alt="Quiz 1" className="quiz-icon" />
                <h2>APTITUDE</h2>
                <Button colorScheme="green" width="70%">
                  Join Now{" "}
                </Button>
              </div>
            </div>
            <div className="card">
              <div className="test-box">
                <img src={tsssc} alt="Quiz 2" className="quiz-icon" />
                <h2>SSC</h2>
                <Button colorScheme="green" width="70%">
                  Join Now{" "}
                </Button>
              </div>
            </div>
            <div className="card">
              <div className="test-box">
                <img src={tsrailway} alt="Quiz 3" className="quiz-icon" />
                <h2>RAILWAY</h2>
                <Button colorScheme="green" width="70%">
                  Join Now{" "}
                </Button>
              </div>
            </div>
            <div className="card">
              <div className="test-box">
                <img src={tsk12} alt="Quiz 4" className="quiz-icon" />
                <h2>K12</h2>
                <Button colorScheme="green" width="70%">
                  Join Now{" "}
                </Button>
              </div>
            </div>
            <div className="card">
              <div className="test-box">
                <img src={tscuet} alt="Quiz 5" className="quiz-icon" />
                <h2>CUET</h2>
                <Button colorScheme="green" width="70%">
                  Join Now{" "}
                </Button>
              </div>
            </div>
            <div className="card">
              <div className="test-box">
                <img src={tsbanking} alt="Quiz 6" className="quiz-icon" />
                <h2>BANKING</h2>
                <Button colorScheme="green" width="70%">
                  Join Now{" "}
                </Button>
              </div>
            </div>
            <div className="card">
              <div className="test-box">
                <img src={tslanguagetest} alt="Quiz 7" className="quiz-icon" />
                <h2>LANGUAGE TEST</h2>
                <Button colorScheme="green" width="70%">
                  Join Now{" "}
                </Button>
              </div>
            </div>
          </div>
        </section>
      </Box>
      <Footer />
    </>
  );
};

export default Testseries;
